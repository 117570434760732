var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contact-page" }, [
    _c(
      "div",
      { staticClass: "map-container" },
      [
        _c(
          "el-amap",
          {
            staticClass: "amap-box",
            attrs: { vid: "amap-vue", center: _vm.center, zoom: _vm.zoom }
          },
          [
            _c("el-amap-marker", {
              attrs: {
                editable: false,
                draggable: false,
                title: _vm.mapTitle,
                clickable: true,
                label: { content: this.label, offset: [36, -10] }
              }
            })
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "info-container" },
      [
        _c("div", { staticClass: "contact-title" }, [_vm._v("联系我们")]),
        _c("el-divider", {
          staticClass: "line",
          attrs: { "content-position": "left" }
        }),
        _vm._l(_vm.blocks, function(item, index) {
          return _c(
            "div",
            { key: index, staticClass: "info-block" },
            _vm._l(item.titles, function(content, titleIndex) {
              return _c(
                "div",
                {
                  key: titleIndex,
                  staticClass: "info-text",
                  class: { bold: titleIndex === 0 && index < 4 }
                },
                [_vm._v(_vm._s(content))]
              )
            }),
            0
          )
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }