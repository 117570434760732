<template>
  <div class="contact-page">
    <div class="map-container">
      <el-amap class="amap-box" :vid="'amap-vue'" :center="center" :zoom="zoom">
        <el-amap-marker :editable="false" :draggable="false" :title="mapTitle" :clickable="true" :label="{content: this.label, offset: [36, -10]}"></el-amap-marker>
      </el-amap>
    </div>
    <div class="info-container">
      <div class="contact-title">联系我们</div>
      <el-divider class="line" content-position="left"></el-divider>
      <div class="info-block" v-for="(item, index) in blocks" :key="index">
        <div class="info-text" v-for="(content, titleIndex) in item.titles" :key="titleIndex" :class="{ bold: titleIndex === 0 && index < 4}">{{ content }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
export default {
  name: "",
  data() {
    return {
      center: [113.118026,28.201173],
      zoom: 15,
      mapTitle: "长沙市长沙县东六路有色大厦公交站东100米科技新城C7栋4楼",
      label: "<div>长沙市长沙县东六路有色大厦公交站东100米科技新城C7栋4楼</div>",
      blocks: [
        { titles: [ "办公地址：", "长沙市长沙县东六路有色大厦公交站东100米","科技新城C7栋4楼",],},
        { titles: ["办公时间：", "夏季作息时间(7月1日一9月30日)", "上午9：00-12：00，下午13：30-17：30"] },
        { titles: ["冬季作息时间(10月1日一次年6月30日)", "上午9：00-12：00，下午13：00-17：00"] },
      ],
    };
  },
  methods: {},
  beforeRouteEnter(to, from, next) {
    store.commit('navbar/SET_POSITION_BOTTOM')
    let pageIndex = { activeIndex: '7'}
    store.commit('navbar/SET_ACTIVEINDEX', pageIndex)
    next()
  },
};
</script>

<style lang="stylus" scoped>
@media only screen and (min-width: 600px) {
  .contact-page {
    background: #f6f6f6
    display: flex
    justify-content: center
    margin: 40px 30px 10px 30px
  }

  .map-container {
    width: 445px
    height: 420px
    border 1px solid #c8c8c8
  }

  .info-container {
    margin: 0px 0px 0px 38px
  }

  .contact-title {
    font-size 19px
    font-weight 600
    color #0F64A9
  }

  .line {
    margin 10px 0px 20px 0px
    background-color #0F64A9
  }

  .info-block {
    margin-bottom 14px
  }

  .info-text {
    font-size 14px
    
    color #828282
    line-height 26px

    &.bold {
      font-weight bold
      font-size 15px
    }
  }
}

// mobile
@media only screen and (max-width: 600px) {
  .contact-page {
    background #f6f6f6
    display flex
    justify-content center
    flex-direction column
    margin 40px 30px
  }

  .map-container {
    width 100%
    height 400px
  }

  .info-container {
    margin 20px 0px 0px 0px
  }

   .contact-title {
    font-size 19px
    font-weight 600
    color #0F64A9
  }

  .line {
    margin 10px 0px 20px 0px
    background-color #0F64A9
  }

  .info-block {
    margin-bottom 16px
  }

  .info-text {
    font-size 16px
    
    color #828282
    line-height 22px
  }
}
</style>